@import "_app.scss";


.error404 {
  padding: 30px 0;
  img {
    display: block;
    margin: 0 auto 30px;
  }
  p {
    margin: 0 0 10px;
    text-align: center;
    font-size: 17px;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
    }
  }
}
#content .pageIntro {
  [class^="wrapper"] {
    overflow: visible;
  }
  img {
    right: 30px;
    bottom: -20px;
  }
}
